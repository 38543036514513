.disclaimer-card {
  min-height: 6em;
  position: relative;
  height: 100%;
  border-radius: 0px 14px 14px 0px;
  background: rgba(76, 160, 216, 0.26);
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.disclaimer-badge-bg {
  background-color: #1d547d;
  position: absolute;
  width: 2em;
  height: 100%;
  top: 0;
}

.disclaimer-card .text-title {
  font-size: 1.125em;
  line-height: 1.11;
}

.disclaimer-badge {
  font-weight: 800;
  font-size: 1em;
  line-height: 1.95em;
  text-transform: uppercase;
  color: white;
  position: absolute;
  left: 0;
  top: 50%;
  writing-mode: vertical-rl;
  transform: translateY(-50%) rotate(180deg);
}

.disclaimer-card-mic-img {
  width: 100%;
  max-width: 3em;
}

@media (max-width: 500px) {
  .disclaimer-card-mic-img {
    max-width: 3.5em;
  }
}

.disclaimer-card-description {
  color: #00000050;
}
