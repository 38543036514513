.patient-card a:hover {
  color: var(--dark);
}

.patient-card a {
  color: #fff;
}

.patient-card .card-header {
  color: var(--docket-light-primary);
  background-color: #e7f5ff;
  align-items: center;
  display: flex;
  flex: 1;
}

@media (max-width: 500px) {
  .patient-name {
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 2em);
    white-space: nowrap;
  }
}

.patient-name-controls {
  width: 4em;
}

.patient-card .card-header div {
  color: inherit !important;
}

.ph-level {
  background-color: #237bb8;
  border-top: #0a0a0a;
  border-top-width: thin;
  /*min-height: 92px;*/
  /*max-height: 98px;*/
}

.record-card-right-img {
  width: 5em;
}

@media (max-width: 500px) {
  .record-card-right-img {
    max-width: 25%;
  }
}
