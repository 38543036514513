.icon-button {
  background-color: var(--docket-light-primary);
  background-size: contain;
  height: 2.4em;
  border-radius: 0.25em;
  width: 2.4em;
  background-repeat: no-repeat;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  background-position: center;
}

.icon-button:hover {
  background-color: var(--docket-lightest-primary);
}

.icon-button.pdf {
  background-image: url(/public/assets/images/PDFIcon.svg);
}

.icon-button.refresh {
  background-image: url(/public/assets/images/refreshIcon.svg);
}

.icon-button.trash {
  background-image: url(/public/assets/images/deleteIcon.svg);
}

.icon-button.faq {
  background-image: url(/public/assets/images/faqIcon.svg);
  background-size: 45%;
}
