.search-card {
  background-color: var(--cardBackgroundColor);
  box-sizing: border-box;
  position: relative;
  padding-left: 6em;
  --status-purple: #8938af;
  --status-green: #38af8b;
  --status-yellow: #efba00;
  --status-red: #e54b09;
  cursor: pointer;
}

.search-card:hover {
  background-color: #f3f3f3;
}

.search-card.in-queue {
  background-color: var(--in-queue-background);
  box-sizing: border-box;
  position: relative;
  padding-left: 6em;
}

.media-content {
  overflow: visible !important;
  white-space: nowrap;
}

.fa-chevron {
  color: darkblue;
}

.badge {
  width: 4em;
  background: var(--red);
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
}

.badge.red {
  background-color: var(--status-red);
  background-image: url(../../../public/assets/images/red.svg);
}

.badge.purple {
  background-color: var(--status-purple);
  background-image: url(../../../public/assets/images/purple.svg);
}

.badge.yellow {
  background-color: var(--status-yellow);
  background-image: url(../../../public/assets/images/yellow.svg);
}

.badge.green {
  background-color: var(--status-green);
  background-image: url(../../../public/assets/images/green.svg);
}

/*.disclaimer-card-left-img-container {*/
/*    background-color: #10375c;*/
/*    min-height: 110px;*/
/*    width: 30px;*/
/*}*/

/*.disclaimer-card-left-img {*/
/*}*/

/*.disclaimer-card-mic-img {*/
/*    max-height: 80px;*/
/*}*/

/*.disclaimer-card-description {*/
/*    color: #00000050;*/
/*}*/
