.squrrel-loader {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.squirrel-wrapper {
  border-radius: 10%;
  border: 3px solid #4783bb;
  height: 150px;
  width: 150px;
  margin: auto;
}
