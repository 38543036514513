.datepicker-wrapper {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
}

.date-labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.date-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-modal {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  z-index: 10;
}
