.form-container {
  padding: 28px;
}

.radio-control {
  display: grid;
  grid-template-columns: 1em auto;
  align-items: center;
  gap: 0.5em;
}

.radio-control.disabled.disabled {
  color: darkgrey;
}

.radio-control.disabled input {
  background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
  color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
  border-color: rgba(118, 118, 118, 0.3);
}

.required-tag {
  font-weight: 400;
}
