.docket-modal .modal-card {
  background: white;
  border-radius: 0.5em;
  border: 2px solid var(--docket-lightest-primary);
  max-width: 90vw;
  box-sizing: border-box;
}

.docket-modal .modal-card-foot {
  background: transparent;
  border: none;
  display: flex;
  justify-content: flex-start;
  padding-top: 0.5em;
  gap: 0.3em;
  flex-wrap: wrap;
  align-items: start;
}

.docket-modal .modal-card-body {
  padding: 0.5em 1.25em;
}

.docket-modal .modal-card-head {
  background: transparent;
  border: none;
  padding-bottom: 0.5em;
}
