.separater {
  width: 100%;
  height: 0.1em;
  background-color: var(--docket-lightest-primary);
  margin: 2em 0;
}

.patient-list li {
  margin-bottom: 3em;
}

.patient-list li:last-of-type {
  margin-bottom: 0;
}

.records-container {
  padding: 3em;
}

.level-left {
  max-width: calc(100% - 4em);
}

@media (max-width: 500px) {
  .records-container {
    padding: 2em;
  }
}
