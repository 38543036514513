.docket-footer {
  width: 100%;
  height: auto;
  bottom: 0;
  color: #608198;
  padding: 20px;
}

.footer-logo {
  width: 100px;
  opacity: 0.5;
}

.footer-logo:hover {
  opacity: 1;
}
