.hero-head {
  padding-top: 5vh;
}

.hero-head img {
  max-width: 50vw;
}

.hero-footer .sign-up {
  background-color: var(--shaded);
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.logo {
  max-width: 75%;
}

.horizontal-rule {
  background-color: white;
  height: 1px;
}

.login-badge {
  position: absolute;
  right: 0;
  color: black;
  font-size: 1.25em;
  font-weight: 700;
  bottom: 10%;
  padding: 1.5em;
  max-width: 40%;
  border-radius: 1em 0 0 1em;
  background: rgba(255, 255, 255, 0.9);
}

.position-relative .fa-circle-check {
  position: absolute;
  right: 0.75em;
  top: 0.75em;
}

.state-id-img {
  width: auto;
  height: 80%;
}
.height-fitcontent {
  min-height: fit-content;
}

.state-button {
  white-space: wrap;
}

.email-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
}
