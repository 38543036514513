.docket-navbar .navbar-link:hover {
  color: var(--docket-light-primary);
}

.docket-navbar .navbar-item:hover,
.docket-navbar .navbar-item:active {
  color: var(--docket-light-primary);
}

.docket-navbar .navbar-link:after {
  border-color: var(--docket-light-primary);
}

.navbar-absolute {
  @media (max-width: 1023px) {
    position: absolute;
    right: 0;
  }
}
