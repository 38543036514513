.layout-card {
  width: 100%;
  border-radius: 22px;
  max-width: 880px;
  box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.25);
  background: white;
  overflow: hidden;
  margin: auto;
  position: relative;
}

@media (max-width: 500px) {
  .layout-card {
    overflow: visible;
  }
}
