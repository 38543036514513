h1 {
  font-weight: 700;
}

.separator {
  height: 1px;
  margin: 20px 0;
  background-color: var(--docket-light-primary);
}

.is-absolute {
  position: absolute;
}

.form-input-label {
  color: black;
  font-size: 16px;
  font-weight: 700;
}

.input {
  border: 1px solid black;
  border-radius: 2px;
}

.field:not(:last-child) {
  margin-bottom: 0;
}

.input::placeholder {
  color: grey;
}

.signup-image {
  display: flex;
  background: var(--docket-lightest-primary);
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.validation-error {
  width: 100%;
  color: black;
  background: var(--docket-lightest-primary);
  border-radius: 0.15rem;
  font-size: 0.9rem;
  line-height: 1.25rem;
  min-height: 1.25rem;
}

.password-toggle {
  border: none;
  background: none;
  outline: none;
  position: absolute;
  top: 50%;
  color: lightgrey;
  right: 1rem;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-toggle.home {
  right: unset;
  top: unset;
}
.logo {
  max-width: 75%;
}
.docket-signup-image {
  width: 40%;
  margin-top: 4%;
  margin-right: 2%;
  align-self: flex-start;
  margin-left: auto;
}

.branding-image {
  margin: auto;
}

.is-white-text {
  color: #ffffff;
}
