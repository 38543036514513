.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: all 0.3s;
}
.switch::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  top: 1px;
  left: 1px;
  transition: all 0.3s;
}

.check-switch:disabled + switch {
  background-color: rgba(0, 0, 0, 0.15);
}

.check-switch:checked + .switch::after {
  left: 20px;
}
.check-switch:checked + .switch {
  background-color: var(--primary-color);
}
.check-switch {
  display: none;
}
