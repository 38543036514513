.screening-button {
  white-space: wrap;
  height: auto;
}

.screening-faq ul {
  list-style-type: disc;
}

.screening-faq ul li {
  display: list-item;
}
