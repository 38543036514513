.navbar-menu {
  height: 90px;
}

.docket-tabs ul {
  border-bottom: 0;
}

.docket-tabs.tabs li {
  background-color: #084b7d;
  color: white;
}

.docket-tabs.tabs li a:hover {
  color: #ccc;
}

.docket-tabs.tabs li a {
  border-bottom: 0;
  font-weight: 600;
}

.docket-tabs.tabs li.is-active {
  background-color: white;
}

.docket-tabs.tabs li.is-active a {
  border-color: transparent;
  color: black;
}

.docket-tabs li,
.docket-tabs li.is-active,
.docket-tabs li:hover {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.navbar,
.footer {
  background-color: transparent;
}

.navbar-menu.is-active {
  color: var(--dark);
}

.navbar-item > img {
  max-height: 3.6rem;
}

.vl {
  border-left: 1px solid grey;
  height: 28px;
  margin-top: 31px;
  margin-left: 10px;
  margin-right: 15px;
}

.user-icon {
  color: #e5961e;
}

.middle-column {
  background-color: #237bb8;
}

.left-menu {
  min-height: 92vh;
}

.is-80px {
  width: 80px;
}

hr {
  margin: 10px 0;
  border: 0;
  border-top: 2px solid #ccc;
}

ul.separator {
  list-style: none;
  padding: 0;
  width: 100%;
}

ul.separator li {
  padding-top: 0.5em;
}

ul.separator li::after {
  content: "";
  display: block;
  border-bottom: 1px solid #ccc;
  padding-top: 0.5rem;
}

ul.separator li:last-child::after {
  display: none;
}

.docket-main-card {
  border-radius: 8px;
}
