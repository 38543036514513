.record-card {
  background-color: var(--cardBackgroundColor);
  position: relative;
  min-height: 8em;
  height: 100%;
  cursor: pointer;
  border-radius: 0px 14px 14px 0px;
  background: #f8f8f8;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
}

.record-badge-bg {
  position: absolute;
  width: 2em;
  height: 100%;
  top: 0;
  left: 0;
}

.record-card .card-title {
  font-size: 1.125em;
  line-height: 1.11;
}

.record-badge {
  font-weight: 800;
  white-space: nowrap;
  font-size: 1em;
  line-height: 1.95em;
  text-transform: uppercase;
  color: white;
  position: absolute;
  left: 0;
  top: 50%;
  writing-mode: vertical-rl;
  transform: translateY(-50%) rotate(180deg);
}

.is-fullheight {
  height: 100%;
}

.record-card-description {
  color: #187bbd;
}

.dueOrUpdateStatus {
  background-color: #f1c40f;
}

.overdueStatus {
  background-color: #e74d3d;
}

.immuneStatus {
  background-color: #8d48ab;
}

.currentStatus {
  background-color: #2ecc71;
}

.recordStatus {
  background-color: #1d547d;
}

.processingStatus {
  background-color: #9e9e9e;
}

.noRecordsStatus {
  background-color: #9e9e9e;
}

.failStatus {
  background-color: #e74d3d;
}

.passStatus {
  background-color: #2ecc71;
}
