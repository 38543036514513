.event-card-header {
  text-align: center;
  border-radius: 8px;
}

.status-badge {
  border-radius: 10px;
}

.event-card {
  border-radius: 8px;
}
