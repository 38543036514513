.settings-header-label {
  color: var(--formInputLabelColor);
}

hr.new1 {
  border-top: 1px solid var(--formInputLabelColor);
}

.blue-button {
  background-color: var(--formInputLabelColor);
}
